<template>
  <div>
      <div id="surveyEditorContainer"></div>
  </div>
</template>

<script>
import * as SurveyEditor from 'surveyjs-editor'
import 'surveyjs-editor/surveyeditor.css'

export default {
  name: 'survey-editor',
  data () {
    return {
    }
  },
  mounted () {
    const editorOptions = { showEmbededSurveyTab: true }
    this.editor = new SurveyEditor.SurveyEditor('surveyEditorContainer', editorOptions)
    this.editor.saveSurveyFunc = function () {
      console.log(JSON.stringify(this.text))
    }
  }
}
</script>
